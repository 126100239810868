import { createStore } from 'vuex'
import user from './modules/user'
import items from './modules/items'
import media from './modules/media'
import cf from './modules/cf'
import block from './modules/blockEditor'
import pdfs from './modules/pdfs'
import widgets from './modules/widgets'
import menuEmits from './modules/menus'
import loginModal from './modules/loginModal'

export default createStore({
  modules: {
    user,
    items,
    media,
    cf,
    block,
    pdfs,
    widgets,
    menuEmits,
    loginModal
  }
})
