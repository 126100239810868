<template>
  <img class="site-logo" src="../assets/img/bastajkorak.png" />
  <div class="main-menu">
    <div class="navigation-menu">
      <ul>
        <li>
          <router-link
            :to="{
              name: 'Dashboard',
            }"
          >
            <font-awesome-icon
              :icon="['fas', 'tachometer-alt']"
              class="nav_menu_icons"
            />
            Dashboard
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'Users',
            }"
          >
            <font-awesome-icon
              :icon="['fas', 'users']"
              class="nav_menu_icons"
            />
            Users
          </router-link>
        </li>

      </ul>
      <div class="logout_button">
        <el-button type="warning" plain @click="logOutUser">Log out</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavigationMenu",
  methods: {
    logOutUser() {
      this.$cookies.remove("bearerToken");
      this.$store.dispatch("logOutUser");
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.nav_menu_icons {
  margin-right: 5px;
  min-width: 30px;
}
.site-logo {
  max-width: 150px;
  margin: 20px auto;
  display: block;
  max-width: 90px;
}
</style>
