import { blockParser } from '../helper/blockParser'

export default {
  state: {
    pageIdentity: {
      title: '',
      id: 0,
      status: '',
      featureImage: ''
    },
    blockList: [],
    listOfBlocks: [],
    lastRemoved: 0,
    pageId: ""
  },
  mutations: {
    SET_PAGE_IDENTITY(state, pageIdentity) {
      state.pageIdentity = {
        ...state.pageIdentity,
        ...pageIdentity
      }
    },
    UPDATE_PAGE_IDENTITY(state, pageIdentity) {
      state.pageIdentity = {
        ...state.pageIdentity,
        ...pageIdentity
      }
    },
    ADD_BLOCK_NAME_TO_LIST(state, newBlock) {
      state.listOfBlocks.push(newBlock)
    },
    COLLECT_BLOCK_CONTENT() {
      return true;
    },
    ADD_BLOCK_TO_LIST(state, newBLock) {
      state.blockList.push(newBLock)
    },
    UPDATE_BLOCK_CONTENT(state, content) {
      const blockId = content.blockData.id;
      state.blockList = state.blockList.map(obj => {
        if (obj.id === blockId) {
          return { ...obj, block_content: content.blockContent.content ? content.blockContent.content : content.blockContent };
        }
        return obj;
      });
    },
    REMOVE_BLOCK_FROM_LIST(state, id) {
      state.lastRemoved = id;
      return state.blockList = state.blockList
        .filter(function (element) {
          return element.id !== id;
        });
    },
    UPDATE_ORDER_BY_ID(state, ids) {
      let objIndex = state.blockList.findIndex((obj => obj.id == ids.id));
      state.blockList[objIndex].order = ids.order
    },
    UPDATE_BLOCK_FROM_API(state, payload) {
      state.blockList = payload;
    },
    RESET_TO_DEF(state) {
      state.pageIdentity = {
        title: '',
        id: 0,
        status: '',
        featureImage: ''
      }
      state.blockList = [];
      state.listOfBlocks = [];
      state.lastRemoved = 0;
    },
    SET_PAGE_ID(state, id) {
      state.pageId = id;
    },
    RESET_PAGE_ID(state) {
      state.pageId = "";
    },
    REODER_BLOCKS(state) {
      state.blockList.map((b, i) => {
        return b.order = i
      })
    }
  },
  actions: {
    setPageIdentity({ commit }, pageIdentity) {
      commit('SET_PAGE_IDENTITY', pageIdentity)
    },
    updatePageIdentity({ commit }, pageIdentity) {
      commit('UPDATE_PAGE_IDENTITY', pageIdentity)
    },
    addBlockNameToList({ commit }, newBlock) {
      commit("ADD_BLOCK_NAME_TO_LIST", newBlock)
    },
    collectBlockData({ commit }) {
      commit("COLLECT_BLOCK_CONTENT")
    },
    addBlockToList({ commit }, newBlock) {
      commit("ADD_BLOCK_TO_LIST", newBlock)
    },
    updateBlockContent({ commit }, content) {
      commit("UPDATE_BLOCK_CONTENT", content)
    },
    removeBlockFromList({ commit }, id) {
      commit("REMOVE_BLOCK_FROM_LIST", id)
    },
    updateOrderById({ commit }, ids) {
      commit("UPDATE_ORDER_BY_ID", ids)
    },
    updateBlockFromAPI({ commit }, payload) {
      payload.map(b => {
        commit("ADD_BLOCK_NAME_TO_LIST", b.name)
      })
      commit("UPDATE_BLOCK_FROM_API", payload)
    },
    resetToDef({ commit }) {
      commit("RESET_TO_DEF")
    },
    setPageId({ commit }, id) {
      commit("SET_PAGE_ID", id)
    },
    resetPageId({ commit }) {
      commit("RESET_PAGE_ID")
    },
    reoderBlocks({ commit }) {
      commit("REODER_BLOCKS")
    }
  }
}
