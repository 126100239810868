<template>
  <div class="fixed">
    <div class="login">
      <div class="container">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4 login-form">
            <img src="@/assets/img/bastajkorak.png" />
            <p class="login-form__p">
              Your session expired, please log in to continue.
            </p>

            <form @submit.prevent="submit">
              <el-input
                v-model="userData.email"
                required
                placeholder="Email*"
                v-on:keyup.enter="submit"
              ></el-input>
              <el-input
                v-model="userData.password"
                required
                placeholder="password*"
                type="password"
                v-on:keyup.enter="submit"
              ></el-input>
              <div class="login-form__buttons">
                <el-button
                  class="mr-4 login-form__submit-button"
                  @click="submit"
                >
                  Login
                </el-button>
              </div>
            </form>
            <div class="login-form__footer">
              <div class="forgot_password">
                <!--              <a href="#" class="forgot_password__a">Forgot password?</a>-->
              </div>
            </div>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "LoginModal",
  data: () => ({
    userData: {
      email: "",
      password: "",
    },
  }),
  methods: {
    submit() {
      const loader = this.$loading.show();
      api
        .post("api/auth/login", {
          email: this.userData.email,
          password: this.userData.password,
        })
        .then((results) => {
          this.$store.dispatch("setUserObject", results.data);
          this.$cookies.set("bearerToken", results.data.access_token);
          this.$store.dispatch("disableLoginModal");
          loader.hide();
        })
        .catch((error) => {
          if (error?.request?.status === 401) {
            this.$notify.error({
              title: "Error",
              message: "Please check you credentials!",
              type: "error",
            });
          }
          loader.hide();
        });
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.517);
}
.login {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-position: bottom right;
  background-size: 600px;
  background-repeat: no-repeat;
}

.login-form {
  max-width: 450px;
  margin: 0 auto;
  padding: 40px;
  background-color: #1f3c2ac8;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
}
.login-form img {
  margin: 0 auto;
  margin-bottom: 20px;
}

.login-form__h2 {
  color: white;
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
}

.login-form__p {
  margin-bottom: 30px;
  font-size: 22px;
  color: white;
  text-align: center;
}

.login-form__buttons {
  margin-top: 30px;
  padding: 15px 30px;
}

.login-form__buttons .login-form__submit-button {
  background-color: #403f37;
  border: 2px solid #403f37;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.4s ease;
}

.login-form__buttons .login-form__submit-button:hover {
  color: #fff;
  border-color: #403f37;
  background-color: transparent;
}

.login-form__submit-button {
  width: 100%;
  border-radius: 40px;
}

.login-form__footer {
  margin-top: 30px;
}

.login-form .el-input {
  margin-bottom: 15px;
}

.forgot_password__a {
  color: white;
  float: right;
  text-decoration: none;
}
@media (max-width: 991px) {
  .login-form {
    max-width: 800px;
  }
}
</style>
