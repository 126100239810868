import api from '../../services/api'

export default {
  state: {
    images: null,
    documents: null,
    audio: null,
    mediaData: {},
    isSearch: false,
    tabIndex: 1,
    imageMediaData: {}
  },
  mutations: {
    SET_IMAGES_DATA(state, images) {
      state.images = images
    },
    SET_DOCUMENTS_DATA(state, documents) {
      state.documents = documents
    },
    SET_AUDIO_DATA(state, audio) {
      state.audio = audio
    },
    DELETE_SINGLE_MEDIA(state, id) {
      state.images.map((singleMedia, index) => {
        if (singleMedia.id === id) {
          state.images.splice(index, 1)
        }
      })
      state.documents.map((singleMedia, index) => {
        if (singleMedia.id === id) {
          state.documents.splice(index, 1)
        }
      })
      state.audio.map((singleMedia, index) => {
        if (singleMedia.id === id) {
          state.audio.splice(index, 1)
        }
      })
    },
    UPDATE_MEDIA_DATA(state, data) {
      state.mediaData = data;
    },
    RESET_MEDIA(state) {
      return state.images = [];
    },
    UPDATE_MEDIA(state, media) {
      let localMedia = []
      media.data.map(singleImage => {
        singleImage.src = process.env.VUE_APP_SHARED_API + singleImage?.properties?.medium
        singleImage.media = 'image'
        localMedia.push(singleImage);
      });
      return state.images = state.images.concat(localMedia)
    },
    SEARCH_MEDIA(state, media) {
      let localMedia = []
      media.data.map(singleImage => {
        singleImage.src = process.env.VUE_APP_SHARED_API + singleImage.slug
        singleImage.media = 'image'
        localMedia.push(singleImage);
      });
      return state.images = localMedia
    },
    REMOVE_FROM_MEDIA(state, id) {
      state.images = state.images.filter(m => m.id !== id)
    },
    SET_TAB_INDEX(state, i) {
      return state.tabIndex = i;
    },
    SET_MEDIA_DATA_AS_LAST(state, payload) {
      return state.images.unshift(payload)
    },
    UPDATE_IMAGE_MEDIA_DATA(state, obj) {
      return state.imageMediaData = obj;
    }
  },
  actions: {
    setMediaData({ commit }, type) {
      return api.get('api/media?per_page=10&sort=DESC&type=' + type)
        .then((response) => {
          if (type === 'image') {
            commit("UPDATE_IMAGE_MEDIA_DATA", {
              current_page: response.data.current_page,
              last_page: response.data.last_page
            })
            commit('SET_IMAGES_DATA', response.data.data.map(singleImage => {
              singleImage.src = process.env.VUE_APP_SHARED_API + singleImage.properties.medium
              singleImage.media = 'image'
              return singleImage
            }))
          } else if (type === 'documents') {
            commit('SET_DOCUMENTS_DATA', response.data.data.map(singleDoc => {
              singleDoc.src = process.env.VUE_APP_SHARED_API + singleDoc.slug
              singleDoc.media = 'document'
              return singleDoc
            }))
          } else if (type === 'audio') {
            commit('SET_AUDIO_DATA', response.data.data.map(singleAudio => {
              singleAudio.src = process.env.VUE_APP_SHARED_API + singleAudio.slug
              singleAudio.media = 'audio'
              return singleAudio
            }))
          }
        })
    },
    deleteMedia({ commit }, id) {
      return api.delete('api/media/' + id)
        .then((response) => {
          commit('DELETE_SINGLE_MEDIA', id)
        })
    },
    setMediaDataAsLast({ commit }, image) {
      commit("SET_MEDIA_DATA_AS_LAST", image)
    },
    updateMedia({ commit }, media) {
      commit("UPDATE_MEDIA", media)
    },
    searchMedia({ commit }, media) {
      commit("RESET_MEDIA")
      commit("SEARCH_MEDIA", media)
    },
    removeFromMedia({ commit }, id) {
      commit("REMOVE_FROM_MEDIA", id)
    },
    setTabIndex({ commit }, i) {
      commit("SET_TAB_INDEX", i)
    }
  }
}
