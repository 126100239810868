import axios from 'axios'
import app from '../main.js'
import store from '../store/index'

const api = axios.create({
  baseURL: process.env.VUE_APP_SHARED_API,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
})

api.interceptors.request.use(
  async config => {
    const bearerToken = app.config.globalProperties.$cookies.get('bearerToken')
    config.headers.Authorization = 'Bearer ' + bearerToken
    return config
  }
)

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const bearerToken = app.config.globalProperties.$cookies.get('bearerToken')
  let currentUrl = window.location.pathname;
  if (401 === error.response.status) {
    if (!bearerToken && currentUrl !== '/login') {
      return store.dispatch("enableLoginModal")
    }
  } else {
    return Promise.reject(error);
  }
});

export default api
