<template>
  <div>
    <Login v-if="$store.state.user.user == null"></Login>
    <div v-else>
      <section :class="[{ active: openMenu }, { 'navigation-bar': true }]">
        <div class="overlay"></div>
        <NavMenu></NavMenu>
      </section>
      <section class="main-section">
        <div
          v-click-outside="handleFocusOut"
          @click="openMenu = !openMenu"
          class="ham-menu bg"
        >
          <div :class="[{ in: openMenu }, { 'arrow anim': true }]" id="arrow">
            <div class="icon-line"></div>
            <div class="icon-line"></div>
            <div class="icon-line"></div>
          </div>
        </div>
        <router-view />
      </section>
    </div>
  </div>
  <LoginModal v-if="$store.state.loginModal.enabled" />
</template>

<script>
import NavMenu from "./views/NavMenu";
import Login from "./views/user/Login";
import LoginModal from "./components/auth/loginModal.vue";

export default {
  data() {
    return {
      openMenu: false,
    };
  },
  components: {
    NavMenu,
    Login,
    LoginModal,
  },
  mounted() {
    const bearerToken = this.$cookies.get("bearerToken");
    if (bearerToken) {
      this.$store.dispatch("setUserObject", bearerToken);
    }
    document.title = "Baš taj korak";
  },
  methods: {
    handleFocusOut() {
      if (this.openMenu) this.openMenu = !this.openMenu;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #1f3c2a;
    }
  }
}
.bg #arrow {
  background-color: #1f3c2a;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.bg #arrow::after {
  content: "";
  left: 58%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: #1f3c2a;
  border-radius: 50%;
  z-index: -1;
}
</style>
