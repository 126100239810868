export default {
  state: {
    serialNumbers: [],
    cf: {
      artworks: {
        collectorTrendsTitle: '',
        collectorTrendsDeletedIds: [],
        taxCollectorId: 0,
        colors: [],
        types: [],
        collector_items: [],
        artistId: 0,
        price: 0,
        sizeWidth: 0,
        sizeHeight: 0,
        quantity: 0,
        valueIndicators: [],
        gallery: [],
        collectorTrends: [],
        techniques: [],
        validateSubFields: true,
        tags: [],
        nonRequired: [
          "nonRequired",
          "valueIndicatorsCounter",
          "nonRequiredSub",
          "validateSubFields",
          "id",
          "featured_image_id",
          "tags",
          "link",
          "collectorTrendsDeletedIds",
          "collector_items",
          // "title",
          // "description",

          // taxonomies!
          "taxCollectorId",
          "colors",
          "types",
          "gallery",
          // "collectorTrends",
          "valueIndicators",
          'collectorTrendsTitle'
        ],
      },
      artists: {
        valueIndicator: {},
        short_description: "",
        born: 0,
        died: 0,
        biography: "",
        awards: [],
        exhibitions: [],
        validateSubFields: true,
        nonRequired: [
          'nonRequired',
          'valueIndicatorsCounter',
          'validateSubFields',
          'died',
          'valueIndicator',
          'awards',
          'exhibitions',
          'title',
          'year'
        ],
      },
      techniques: {
        valueIndicator: {},
        validateSubFields: true,
        principles: "",
        artist_ids: [],
        pdfData: {},
        nonRequired: [
          'nonRequired',
          'nonRequiredSub',
          'validateSubFields',
          'pdfData',
          'id',
          'valueIndicator',
          'valueIndicators',
          'iconUrl',
        ],
      }
    }
  },
  mutations: {
    UPDATE_VALUE(state, payload) {
      state.cf[payload.type] = {
        ...state.cf[payload.type],
        ...payload.data
      }
    },
    UPGRADE_VALUE(state, payload) {

      state.cf[payload.type] = {
        ...state.cf[payload.type],
        ...payload.data
      }
    },
    COLLECT_CF_DATA(state) {
      return state;
    },
    COLLECT_FROM_CF(state) {
      return state;
    },
    BIND_VALUE_INDICATORS(state, payload) {
      if (state.cf[payload.type].valueIndicators[payload.index] && state.cf[payload.type].valueIndicators.length <= 2) {
        state.cf[payload.type].valueIndicators[payload.index].imageUrl = payload.data.icon_url;
        state.cf[payload.type].valueIndicators[payload.index].title = payload.data.title;
        state.cf[payload.type].valueIndicators[payload.index].textList = payload.data.textList;
      } else if (state.cf[payload.type].valueIndicators.length <= 2) {
        state.cf[payload.type].valueIndicators.push({
          imageUrl: payload.data.icon_url,
          title: payload.data.title,
          textList: payload.data.textList,
        })
      }
    },
    RESET_IMG_SELECTOR(state) {
      return state;
    },
    RESET_COLLECT_FROM_CF(state) {
      state.cf.artworks.valueIndicators = [];
    },
    RESET_STATE(state) {
      state.cf.artworks = {
        taxCollectorId: 0,
        colors: [],
        types: [],
        artistId: 0,
        price: 0,
        sizeWidth: 0,
        sizeHeight: 0,
        valueIndicators: [],
        gallery: [],
        collectorTrends: [],
        techniques: [],
        validateSubFields: true,
        nonRequired: [
          "nonRequired",
          "valueIndicatorsCounter",
          "nonRequiredSub",
          "validateSubFields",
          "id",
          "featured_image_id",
          "title",
          "description",

          // taxonomies!
          "taxCollectorId",
          "colors",
          "types",
          'collectorTrendsTitle'
        ],
        nonRequiredSub: [
          "nonRequired",
          "valueIndicatorsCounter",
          "nonRequiredSub",
          "validateSubFields",
          "imageUrl",
          "id",
          "description",
          "featuredImage",
          "featured_image_id",
          "title",
          "description",

          // taxonomies!
          "taxCollectorId",
          "colors",
          "types",
          'collectorTrendsTitle'
        ],
      };
      state.cf.artists = {
        valueIndicator: {},
        short_description: "",
        born: 0,
        died: 0,
        biography: "",
        awards: [],
        exhibitions: [],
        validateSubFields: true,
        nonRequired: [
          'nonRequired',
          'valueIndicatorsCounter',
          'nonRequiredSub',
          'validateSubFields',
          'died'
        ],
        nonRequiredSub: [
          'nonRequired',
          'nonRequiredSub',
          'validateSubFields',
          'died'
        ]
      };
      state.cf.techniques = {
        valueIndicator: {},
        validateSubFields: true,
        principles: "",
        artist_ids: [],
        pdfData: {},
        nonRequired: [
          'nonRequired',
          'nonRequiredSub',
          'validateSubFields',
          'pdfData',
          'id'
        ],
        nonRequiredSub: [
          'nonRequired',
          'nonRequiredSub',
          'validateSubFields',
          'pdfData',
          'id'
        ]
      };
    },
    REMOVE_FROM_TECHNIQUES(state, payload) {
      let index = state.cf.artworks.techniques.findIndex(function (o) {
        return o.id === payload;
      })
      if (index !== -1) state.cf.artworks.techniques.splice(index, 1);
    },
    ADD_COLLECTOR_ID_TO_REMOVE(state, id) {
      state.cf.artworks.collectorTrendsDeletedIds.push(id)
    },
    REMOVE_FROM_TAGS(state, id) {
      state.cf.artworks.tags = [];
    },
    SERIAL_NUMBERS(state, numbers) {
      let modifyArr = [];
      numbers.map(number => {
        modifyArr.push({
          ...number,
          available: number.available == 0 ? false : true
        })
      })
      state.serialNumbers = modifyArr;
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("UPDATE_VALUE", payload)
    },
    fetchSerialNumbers({ commit }, payload) {
      commit("SERIAL_NUMBERS", payload)
    },
    upgradeValue({ commit }, payload) {
      commit("UPGRADE_VALUE", payload)
    },
    collectData({ commit }) {
      commit("COLLECT_CF_DATA");
    },
    bindValueIndicators({ commit }, value) {
      commit("BIND_VALUE_INDICATORS", value)
    },
    resetImgSelector({ commit }) {
      commit("RESET_IMG_SELECTOR")
    },
    collectFromCF({ commit }) {
      commit("COLLECT_FROM_CF")
    },
    resetCollectFromCf({ commit }) {
      commit("RESET_COLLECT_FROM_CF")
    },
    resetState({ commit }) {
      commit("RESET_STATE")
    },
    removeFromTechniquesArr({ commit }, payload) {
      commit('REMOVE_FROM_TECHNIQUES', payload)
    },
    addCollectorIdForRemove({ commit }, id) {
      commit('ADD_COLLECTOR_ID_TO_REMOVE', id)
    },
    removeFromTags({ commit }) {
      commit('REMOVE_FROM_TAGS')
    }
  }
}
