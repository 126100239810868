<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Dashboard</div>
  </div>
  <div class="home">
    <div class="items">
      <div>
        <div class="row mt-3 items_form_title">
          <div class="col-1"></div>
          <div class="col-1">User id</div>
          <div class="col-3">First Name</div>
          <div class="col-3">Last Name</div>
          <div class="col-2">Steps</div>
        </div>
        <div class="row item_list">
          <div
            class="row single_row_item"
            v-for="(item, index) in usersData"
            :key="index"
          >
            <div class="col-1">
              {{index + 1}}
            </div>
            <div class="col-1">
              {{item.id}}
            </div>
            <div class="col-3">
              {{ item.first_name }}
            </div>
            <div class="col-3">
              {{ item.last_name }}
            </div>
            <div class="col-2">
              {{ item.steps }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      usersData: [],
    };
  },
  mounted() {
    this.fetchData().then((res) => {
      if (res.data.length > 0) {
        this.usersData = res.data;
      }
    });
  },
  methods: {
    async fetchData() {
      const loader = this.$loading.show();
      return await this.$api
        .get(
          `${
            process.env.VUE_APP_SHARED_API
          }api/client/rang-list`
        )
        .then((res) => {
          loader.hide();
          return res;
        });
    },
  },
};
</script>

<style lang="scss">
.home {
  text-align: center;
  .dashboard-logo {
    margin-bottom: 50px;
  }
}
.card {
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
  --bs-card-border-width: 0px;
}

.widget-icon {
  color: #1f3c2a;
  font-size: 20px;
  background-color: rgba(#1f3c2a, 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}

.home {
  text-align: left;
}

.report_btn {
  margin: 10px;
  padding: 10px 30px;
  background: rgba(31, 60, 42, 0.5);
  font-size: 20px;
  text-transform: uppercase;
  border: none;
  color: #fff;
}

.report_btn.active {
  background: rgba(31, 60, 42, 1);
}
@media (max-width: 768px) {
  .report_btn {
    font-size: 15px;
  }
}

.serach-wrapper {
  display: flex;
  max-width: 300px;
}
.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #1f3c2a;
  }
}

.users_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
}

.user {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  width: 23.6034%;

  .user_image {
    text-align: center;
    padding-bottom: 30px;

    img {
      max-width: 150px;
    }
  }

  .user_content {
    h3 {
      font-size: 24px;
    }

    p {
      margin-bottom: 30px;
    }

    button {
      background-color: #1f3c2a;
      text-transform: uppercase;
      color: white;
      border: 1px solid #1f3c2a;
      transition: all 0.4s ease;
    }

    button:hover {
      background-color: #fff;
      color: #1f3c2a;
    }
  }
}

.user:nth-child(4) {
  margin-right: 0;
}

.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;
    a {
      display: inline-block;
      background-color: transparent;
      color: #1f3c2a;
      border: 1px solid #1f3c2a;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #1f3c2a;
  }
}

.items {
  padding-right: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
  .items_form_title > div {
    padding: 0 !important;
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #1f3c2a;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #1f3c2a;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #1f3c2a;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;
        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #1f3c2a;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item > * {
      padding: 0;
      overflow-x: scroll;
      padding-right: 5px;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}
.search-export {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
